import React from 'react'

const Subheader = () => {
    return (
        <div className={"subheader-container"}>
            <ul>
                <li><a href="">Home</a></li>
                <li><a href="">Category 1</a></li>
                <li><a href="">Category 2</a></li>
                <li><a href="">Category 3</a></li>
                <li><a href="">Category 4</a></li>
            </ul>
        </div>
    )
}

export default Subheader